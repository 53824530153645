export function shortText(val) {
  let result = val;
  if (val?.length > 12) {
    result = val?.slice(0, 5) + "..." + val?.slice(val?.length - 4, val?.length);
  }
  return result;
}


export function getIPFSUrl(url) {

  if(url && url.indexOf('cloudflare') > - 1) {
    const urlPrefix = "https://ipfs.io/ipfs/";

    const urlObj = new URL(url); // Create a URL object from the input URL
    if(urlObj.pathname) {
      const pathSegments = urlObj.pathname.split('/').filter(segment => segment);
      const lastSegment = pathSegments[pathSegments.length - 1]; // Get the last segment
      const secondToLastSegment = pathSegments[pathSegments.length - 2]; // Get the second-to-last segment

      // Return both segments if both exist, otherwise return the available one
      return secondToLastSegment ? `${urlPrefix}${secondToLastSegment}/${lastSegment}` : `${urlPrefix}${secondToLastSegment}`;
    } 
  }

  return url;
}
