import "bootstrap/dist/css/bootstrap.min.css";
import "./nft-detail.scss";
import { Container, Row, Col } from "reactstrap";
import NFTitems from "../../Common/NFTitems";
// import MintNow from "../../Common/MintNowButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import sxtService from "../../../helpers/service";
import { reloadNFTs } from "../../../redux/states/nftsReducerNew";
// import NFTModal from "../../Common/Modal/NFTModal";

export default function NFTDetailNew() {
  const connection = useSelector((state) => state.connection);
  const contract = useSelector((state) => state.contract);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [isPrevMinted, setisPrevMinted] = useState(null);
  const [isTotalSold, setisTotalSold] = useState(null);
  const [convertedErc20Price, setconvertedErc20Price] = useState(0);

  const isPreviouslyMinted = useCallback(
    async (index) => {
      if (contract && !connection.disconnected) {
        const address = connection.accountAddress;
        let result = false;
        
        if (index) {
          const valIndex  = sxtService.convertToNumber(index)
          result = await window.contract.isAddressUtilized(valIndex, address);
        }
        setisPrevMinted(result || false);
        setLoading(false);
      } else {
        setLoading(false);
        setisPrevMinted(false);
      }
    },
    [connection.accountAddress, connection.disconnected]
  );

  const retrieveNFTs = useCallback(() => {
    setTimeout(async () => {
      if (window.contract && contract.connected) {
        setLoading(true);
        setisTotalSold(false);
        const nftIndex = params.id;
        if (nftIndex > 0) {
          const data = await sxtService.getNFTDetails(nftIndex);
          if (!data) {
            window.showSnackbar("NFT doesn't exists.", "info");
            return navigate("/");
          }
          if (data?.maxTokenSupply === data?.currentTokenSupply) {
            setisTotalSold(true);
          }
          isPreviouslyMinted(data.id);
          setTokenDetails(data);
        } else {
          setLoading(false);
          window.showSnackbar("NFT doesn't exists.", "info");
          navigate("/");
        }
      }
    }, 0);
  }, [contract.connected, isPreviouslyMinted, params.id, navigate]);

  let init = useCallback(() => {
    setLoading(true);
    if (tokenDetails?.id !== params?.id) return retrieveNFTs();
    if (connection.disconnected) return retrieveNFTs();
    if (!connection.disconnected) return retrieveNFTs();
    if (!contract.connected) return;
    if (!location.state) return retrieveNFTs();
    if (location.state) {
      setTokenDetails({ ...location.state });
      setisPrevMinted(location.state.isPrevMinted);
      setLoading(false);
      if (location.state.maxTokenSupply === location.state.currentTokenSupply)
        setisTotalSold(true);
    }
  }, [
    connection.disconnected,
    contract.connected,
    location.state,
    params.id,
    retrieveNFTs,
  ]);

  const isMintProcessPending = useCallback(async () => {
    if (localStorage.getItem("thash")) {
      window.showProgress("Mint processing, Please don't refresh the page.");
      let progressInterval = setInterval(() => {
        sxtService
          .getTransactionReceipt(localStorage.getItem("thash"))
          .then((res) => {
            if (res) {
              localStorage.removeItem("thash");
              window.hideProgress();
              if (res.status === 1) {
                init();
                window.showSuccessModal();
                clearInterval(progressInterval);
                dispatch(reloadNFTs());
              } else {
                window.showSnackbar("Mint process failed.");
                clearInterval(progressInterval);
              }
            }
          });
      }, 4000);
    }
  }, [dispatch, init]);

  useMemo(async () => {
    if (tokenDetails) {
      const erc20P = await sxtService.convertToErc20(tokenDetails.erc20Price);
      setconvertedErc20Price(erc20P);
      return erc20P;
    }
  }, [tokenDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
    isMintProcessPending();
  }, [connection.disconnected, init, isMintProcessPending]);

  const onMintSuccess = () => {
    setLoading(true);
    setisPrevMinted(false);
    setisTotalSold(false);
    retrieveNFTs();
  };

  async function mintClickHandler(type) {
    let isReadyToMint = await sxtService.isEnvironmentReadyToMint();
    if (tokenDetails && isReadyToMint)
      sxtService.mintNFT(
        type,
        tokenDetails.id,
        connection.accountAddress,
        tokenDetails.ethPrice,
        tokenDetails.erc20Price,
        onMintSuccess
      );
  }

  const AlreadyAirdropped = () => {
    const alreadyHave = <span>Congratulations! We have airdropped an instance of this NFT to you.</span>;
      if (!tokenDetails) return <>...</>;
      if (isPrevMinted) return alreadyHave;
  }

  // const MintButtonsNew = () => {
  //   const alreadyHave = <span>Congratulations! We have airdropped an instance of this NFT.</span>;
  //   const allSold = <h5>All NFTs are sold.</h5>;
  //   if (!tokenDetails) return <>...</>;
  //   if (isPrevMinted) return alreadyHave;
  //   if (isTotalSold) return allSold;
  //   const { ethPrice, erc20Price, hasPrice } = tokenDetails;
  //   if (!hasPrice) return <></>;
  //   // return <MintNow clickHandler={() => mintClickHandler("free")} />;

  //   if (ethPrice > 0 && erc20Price > 0) {
  //     return (
  //       // <>
  //       //   <MintNow
  //       //     text={`${sxtService.convertToEth(ethPrice)} ETH`}
  //       //     clickHandler={() => mintClickHandler("eth")}
  //       //   />
  //       //   <MintNow
  //       //     text={`${convertedErc20Price || 0} ${tokenDetails.symbol}`}
  //       //     clickHandler={() => mintClickHandler("erc20")}
  //       //   />
  //       // </>
  //       <></>
  //     );
  //   } else if (ethPrice > 0 && erc20Price.toString() === "0") {
  //     return (
  //       // <MintNow
  //       //   text={`${sxtService.convertToEth(ethPrice)} ETH`}
  //       //   clickHandler={() => mintClickHandler("eth")}
  //       // />
  //       <></>
  //     );
  //   } else if (ethPrice.toString() === "0" && erc20Price > 0) {
  //     return (
  //       // <MintNow
  //       //   text={`${convertedErc20Price || 0} ${tokenDetails.symbol}`}
  //       //   clickHandler={() => mintClickHandler("erc20")}
  //       // />
  //       <></>
  //     );
  //   }
  // };

  function Loader() {
    return (
      <>
        {loading && (
          <div className="d-flex w-100 justify-content-center">
            <CircularProgress color="secondary" size={30} />
          </div>
        )}
      </>
    );
  }

  const DiscordDescription = () => {
    return <span className='discord-details'>Please
     <a href="https://discord.gg/spaceandtimedb" rel="noreferrer" target='_blank' > 
     <span>join the community</span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><defs></defs><g id="图层_2" data-name="图层 2"><g id="Discord_Logos" data-name="Discord Logos"><g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White"><path className="cls-1" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/></g></g></g></svg></a>
     </span>
  }

  function NFTDetails() {
    return (
      <>
        {!loading && tokenDetails && (
          <>
            <Col lg="6" md="12" sm="12" xs="12">
              <div
                className="img-wrapper"
                style={{
                  backgroundImage: `url(${tokenDetails?.imageURL || ""})`,
                }}
              ></div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <h2>{tokenDetails?.name || "SpaceNFT-X"}</h2>
              {!loading ? <AlreadyAirdropped /> : <CircularProgress size={10} />}
              <br />
              <div className="sold-total-item-btn">
                <NFTitems
                  title="Sold Items"
                  quantity={tokenDetails?.currentTokenSupply || 0}
                />
                <NFTitems
                  title="Total Items"
                  quantity={tokenDetails?.maxTokenSupply || 0}
                />
              </div>
              <div className="row">
                <div className="col mb-4  mt-4">
                  <h3>Description</h3>
                  <p>{tokenDetails?.description || "Not available"}</p>
                </div>
                <DiscordDescription />
              </div>
            </Col>
          </>
        )}
      </>
    );
  }

  return (
    <Container>
      <Row className="detail-page-style">
        <Loader />
        <NFTDetails />
      </Row>
    </Container>
  );
}
