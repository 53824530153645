
//eslint-disable-next-line
const ENVRopsten = {
  contractAddress : "0x78C28b274C25C2BaF62669C038272C19DdDa29B7", //final deploy // jake
  erc20ContractAddress: "0x2cCdABBa70bdD8dAAf2e98165efc450bE02e93dE", //ropsten
  otherProviderURL:
    "https://ropsten.infura.io/v3/69fa7729bf15497588dde6ee33b38e33",
};

const ENVMain = {
  //contractAddress: "0xc7e0D56e570bFC856dDF093a70a7797cE8cF1dBA", //testnet
  //contractAddress: "0x455826c957AD205414e06A6C5f86F81E5460235e", //testnet
  //oldContractAddress: "0x926Ee2fB43F6F4403c9137DD049b7468b02D83c2", //testnet
  // oldContractAddress: '0xb4aA22a2853f02232dF5FbeB3842C4Ab3D6d95BC', //testnet
  // old2ContractAddress : "0xE92eD8787FcF6eaC6c12b45054D09D907F80F679", //testnet


  // This contract address is getting attached to window
  contractAddress : "0xaB1303551dce526Ab1238Ed13eF067B3B19F3c94", //mainnet address @ 21-Nov-2022
  old2ContractAddress : "0x1873323e539d264a1a7712932b7FF8aB2F744d71", //mainnet old address AI-speculation @ 04-Nov-2022 (don't change)
  oldContractAddress : "0x03b44fE743Eec9a15715900b809020b1cADC9885", //mainnet old address HYPERLOOP (don't change)
  chainId: 1,
  erc20ContractAddress: "",
  otherProviderURL:
    "https://mainnet.infura.io/v3/aecbcf65641445bbb0992d29ca3430b5",
};

export default ENVMain;
