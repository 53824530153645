import React, { useCallback, useEffect, useState } from "react";
import NFTCard from "../NFTCard";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import sxtService from "../../../helpers/service";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { setSoldNFTs } from "../../../redux/states/nftsReducerNew";
import ABI from "../../../assets/json/contractABI.json";
import ENVMain from "../../../environment";
import { ethers } from "ethers";
import { CircularProgress } from "@mui/material";
import Loading from "./Loading";

export default function FeaturedSlider() {
  const connection = useSelector((state) => state.connection);
  const contract = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  const nftsList = useSelector((state) => state.nftsList);
  const [allNFTS, setallNFTS] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const retrieveNFTsFromOldContract = useCallback(async () => {
  //   const address = ENVMain.oldContractAddress;
  //   const abi = ABI.abi;
  //   const provider = new ethers.providers.JsonRpcProvider(ENVMain.otherProviderURL);
  //   const contract = new ethers.Contract(address, abi, provider);
  //   const soldNFTsOld = await sxtService.retriveNFTsByContract(contract, 1, 1, false);
  // }, [contract.connected]);

  const retrieveNFTs = useCallback(async () => {
    if (contract.connected) {
      /* @04-11-2022 a new contract is deployed to main net */
      // at that time SXT Community has sold a NFT with old contract address
      // that's why, Now we are retrieving sold NFTs from old contract and
      // combined with new contract
      let address = ENVMain.oldContractAddress;
      const abi = ABI.abi;
      const provider = new ethers.providers.JsonRpcProvider(
        ENVMain.otherProviderURL
      );
      const contract = new ethers.Contract(address, abi, provider);
      const soldNFTsOld = await sxtService.retriveNFTsByContract(
        contract,
        1,
        1,
        false
      );
      let oldCards1 = [];
      if (soldNFTsOld) {
        let _cards = [...(soldNFTsOld?.nfts || [])];
        if (_cards.length) {
          oldCards1 = _cards.map((cur) => {
            return { ...cur, isOld: true, contractAddress: address };
          });
        }
      }

      /** now retreive NFTs from second old contract */
      let oldCards2 = [];
      address = ENVMain.old2ContractAddress;
      const contractForOld2 = new ethers.Contract(address, abi, provider);
      const second_soldNFTsOld = await sxtService.retriveNFTsByContract(
        contractForOld2,
        1,
        1,
        false
      );
      if (second_soldNFTsOld) {
        let _cards = [...(second_soldNFTsOld?.nfts || [])];
        if (_cards.length) {
          oldCards2 = _cards.map((cur) => {
            return { ...cur, isOld: true, contractAddress: address };
          });
        }
      }
      
      const soldNFTs4 = await sxtService.retriveNFTs(1, 4, false);

      const soldNFTs = [...(soldNFTs4?.nfts || [])]
      let cards = [...oldCards1, ...oldCards2, ...(soldNFTs || [])];
      if (cards.length > 0) {
        setallNFTS(cards);
        dispatch(setSoldNFTs({ nfts: cards, count: soldNFTs?.length || 0 }));
        setIsLoading(false);
      } else {
        setallNFTS([]);
        dispatch(setSoldNFTs({ nfts: null, count: 0 }));
        setIsLoading(false);
      }
    }
  }, [contract.connected]);

  useEffect(() => {
    retrieveNFTs();
  }, [contract.connected, connection.disconnected]);

  useEffect(() => {
    if (nftsList.fetchNFTs === true) {
      retrieveNFTs();
    }
  }, [nftsList.fetchNFTs]);

  function generateCards(item, index) {
    return (
      <NFTCard
        key={`nft-card-sold-${index}`}
        keyData={`nft-card-sold-${index}`}
        tokenInfo={item}
        disableMint
      />
    );
  }

  return (
    <>
      <Row>
        <Col className="sold-nft-section">
          <h3 className="title-sm text-center">Claimed NFTs</h3>
          {!isLoading && allNFTS && allNFTS?.length > 0 && (
            <Slider {...settings}>{allNFTS.map(generateCards)}</Slider>
          )}
          {isLoading && allNFTS && allNFTS?.length === 0 && <></>}
          {isLoading && !allNFTS && <Loading />}
        </Col>
      </Row>
    </>
  );
}
