import axios from "axios";
import { BigNumber, ethers } from "ethers";
import ENV from "../environment";
import { disconnect } from "../redux/states/connectionReducer";
import { reloadNFTs } from "../redux/states/nftsReducerNew";
import store from "../redux/store";
import ENVMain from "../environment";
import { getIPFSUrl } from '../helpers/globalHelper';

class sxtService {
  // connect with specific contract address
  static async connectContract(contractAddress, abi, isNotSigner = false) {
    let provider = this._getInfuraProvider();
    let contract = null;
    try {
      provider.on("network", this._onNetworkChangeCallback);
      window.ethereum?.on("accountsChanged", this._onAccountChangeCallback);
      const networkProvider = await this._checkConnectedNetwork(provider);
      if (networkProvider) {
        contract = this._generateContractObject(
          // provider,
          this._getInfuraProvider(),
          contractAddress,
          abi,
          isNotSigner
        );
      } else {
        contract = this._generateContractObject(
          this._getInfuraProvider(),
          contractAddress,
          abi,
          true
        );
      }
      window.provider = provider;
      window.contract = contract;
      return contract;
    } catch (err) {
      console.log(err.message);
    }
    return contract;
  }

  static _generateContractObject(provider, contractAddress, abi, isNotSigner) {
    let contract = null;
    try {
      contract = new ethers.Contract(contractAddress, abi, provider);
      if (!isNotSigner)
        contract = new ethers.Contract(
          contractAddress,
          abi,
          provider.getSigner()
        );
    } catch (err) {
      console.log(err.message);
    }

    return contract;
  }

  static _onNetworkChangeCallback(newNetwork, oldNetwork) {
    if (oldNetwork) {
      window.showSnackbar("Network changed", "info");
      window.location.reload();
    }
  }

  static _onAccountChangeCallback(accounts) {
    if (accounts) {
      window.showSnackbar("Account changed", "info");
      window.location.reload();
    }
  }

  static _getWeb3Provider() {
    if (this.isEthereumConnected()) {
      return new ethers.providers.Web3Provider(window.ethereum, "any");
    } else {
      return null;
    }
  }

  static _getInfuraProvider() {
    return new ethers.providers.JsonRpcProvider(ENV.otherProviderURL);
  }

  static _getProvider() {
    if (typeof window.ethereum !== "undefined") {
      return this._getWeb3Provider();
    } else {
      return this._getInfuraProvider();
    }
  }

  static async _checkConnectedNetwork(provider) {
    return new Promise(async (resolved, rejected) => {
      let newProvider = provider;
      if (provider) {
        const network = await provider.getNetwork();
        if (network) {
          if (network.chainId !== ENVMain.chainId) {
            newProvider = null;
            window.showSnackbar(
              `You are on ${network.name} network. Please connect to Mainnet.`,
              "info",
              6000
            );
            localStorage.removeItem("account");
            store.dispatch(disconnect());
          }
          return resolved(newProvider);
        } else {
          return resolved(null);
        }
      } else {
        rejected(
          "checkConnectedNetwork(<provider>) method required valid web3 provider."
        );
      }
    });
  }

  static isEthereumConnected() {
    let result = false;
    window.ethereum
      ? (result = true)
      : window.contract
      ? (result = true)
      : (result = false);
    return result;
  }

  static async getCurrentIndex() {
    try {
      if (this.isEthereumConnected() && window.contract) {
        const currentIndex = await window.contract.currentTokenIndex();
        return currentIndex;
      } else {
        return 0;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  static async getNFTDetails(index) {
    let data = null;
    if (this.isEthereumConnected() && window.contract) {
      if (index > 0) {
        const currentTokenSupply = await window.contract.totalSupply(index);
        const tokenDetails = await window.contract.tokenDetails(index);
        if (tokenDetails.tokenUri !== "") {

          const url = getIPFSUrl(tokenDetails.tokenUri);

          const tokenInfo = await axios.get(url, {
            timeout: 60 * 1000,
          });
          const erc20symbol = await this.getERC20Symbol();
          console.log(tokenDetails.currentTokenSupply, '--supply')
          data = {
            id: index,
            ...tokenDetails,
            ...tokenInfo.data,
            maxTokenSupply: this.convertToNumber(tokenDetails.maxTokenSupply),
            currentTokenSupply: this.convertToNumber(
              currentTokenSupply
            ),
            ethPrice: 0, //this.convertToNumber(tokenDetails.ethPrice),
            erc20Price: 0, //this.convertToNumber(tokenDetails.erc20Price),
            imageURL: `https://ipfs.io/ipfs/${
              tokenInfo.data.image.split("//")[1]
            }`,
            symbol: erc20symbol,
          };
        } else {
          return null;
        }
      }
    }
    // console.log(data);
    return data;
  }

  static async getNFTDetailsByContract(index, contract) {
    let data = null;
    if (contract) {
      if (index > 0) {
        const tokenDetails = await contract.tokenDetails(index);
        if (tokenDetails.tokenUri !== "") {
          const tokenInfo = await axios.get(tokenDetails.tokenUri, {
            timeout: 60 * 1000,
          });
          const erc20symbol = await this.getERC20Symbol();
          data = {
            id: index,
            ...tokenDetails,
            ...tokenInfo.data,
            maxTokenSupply: this.convertToNumber(tokenDetails.maxTokenSupply),
            currentTokenSupply: this.convertToNumber(
              tokenDetails.currentTokenSupply
            ),
            ethPrice: 0, //this.convertToNumber(tokenDetails.ethPrice),
            erc20Price: 0, //this.convertToNumber(tokenDetails.erc20Price),
            imageURL: `https://ipfs.io/ipfs/${
              tokenInfo.data.image.split("//")[1]
            }`,
            symbol: erc20symbol,
          };
        } else {
          return null;
        }
      }
    }
    // console.log(data);
    return data;
  }

  static async retriveNFTs(pageNo, pageSize, isAvailable) {
    if (this.isEthereumConnected() && window.contract) {
      let result = [];
      const erc20symbol = await sxtService.getERC20Symbol();
      const tokenDetails = await window.contract.retrieveNFTs(
        pageNo,
        pageSize,
        isAvailable
      );
      let nftslist = JSON.parse(tokenDetails[0].toString()) || [];
      if (nftslist.length) {
        const l = nftslist.length || 0;
        for (let i = 0; i < l; i++) {
          try {
            const url = getIPFSUrl(nftslist[i].tokenUri);

            const tokenInfo = await axios.get(url, {
              timeout: 60 * 1000,
            });

            const urlPrefix = "https://ipfs.io/ipfs/";
            
            let imageUrl = ''
            if (tokenInfo.data.image) {
              imageUrl = tokenInfo.data.image.split("//")[1] || "";
            }

            const obj = {
              currentTokenSupply: nftslist[i].currentTokenSupply * 1,
              erc20Price: 0, //nftslist[i].erc20Price * 1,
              ethPrice: 0, //nftslist[i].ethPrice * 1,
              hasPrice: false, // nftslist[i].hasPrice === "false" ? false : true,
              id: nftslist[i].id * 1,
              maxTokenSupply: nftslist[i].maxTokenSupply * 1,
              tokenUri: nftslist[i].tokenUri,
              ...tokenInfo.data,
              imageURL: `${urlPrefix}${imageUrl}`,
              symbol: erc20symbol,
            };
            result.push(JSON.parse(JSON.stringify(obj)));
          } catch(error) {
            console.log('error in retrieve', error);
          }
        }
        const d = { nfts: result, count: tokenDetails[1] * 1 };
        return d;
      }
    } else {
      return { nfts: [], count: 0 };
    }
  }

  static async retriveNFTsByContract(contract, pageNo, pageSize, isAvailable) {
    if (this.isEthereumConnected() && contract) {
      let result = [];
      const erc20symbol = await sxtService.getERC20Symbol();
      const tokenDetails = await contract.retrieveNFTs(
        pageNo,
        pageSize,
        isAvailable
      );
      let nftslist = JSON.parse(tokenDetails[0].toString()) || [];
      if (nftslist.length) {
        const l = nftslist.length || 0;
        for (let i = 0; i < l; i++) {
          const tokenInfo = await axios.get(nftslist[i].tokenUri, {
            timeout: 60 * 1000,
          });
          const urlPrefix = "https://ipfs.io/ipfs/";
          let imageUrl = tokenInfo.data.image.split("//")[1] || "";
          const obj = {
            currentTokenSupply: nftslist[i].currentTokenSupply * 1,
            erc20Price: 0, //nftslist[i].erc20Price * 1,
            ethPrice: 0, //nftslist[i].ethPrice * 1,
            hasPrice: false, // nftslist[i].hasPrice === "false" ? false : true,
            id: nftslist[i].id * 1,
            maxTokenSupply: nftslist[i].maxTokenSupply * 1,
            tokenUri: nftslist[i].tokenUri,
            ...tokenInfo.data,
            imageURL: `${urlPrefix}${imageUrl}`,
            symbol: erc20symbol,
          };
          result.push(JSON.parse(JSON.stringify(obj)));
        }
        const d = { nfts: result, count: tokenDetails[1] * 1 };
        return d;
      }
    } else {
      return { nfts: [], count: 0 };
    }
  }

  static convertToNumber(val) {
    let res = 0;
    try {
      res = BigNumber.from(val.toString()).toString();
    } catch (error) {
      console.log(error.message);
      return 0;
    }
    return res;
  }

  static async checkERC20Balance(address) {
    let result = 0;
    try {
      if (window.erc20contract) {
        result = await window.erc20contract.balanceOf(address);
      }
    } catch (error) {
      console.log(error.message);
    }
    return result;
  }

  static async getERC20Symbol() {
    let result = "SXT";
    // try {
    //   if (window.erc20contract) {
    //     result = await window.erc20contract.symbol();
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   return result;
    // }
    return result;
  }

  static async getERC20Decimal() {
    let result = 18;
    // try {
    //   if (window.erc20contract) {
    //     result = await window.erc20contract.decimals();
    //   }
    // } catch (error) {
    //   console.log(error.message);
    // }
    return result;
  }

  static checkConnection(connection) {
    let res = true;
    if (connection.disconnected) {
      res = false;
      if (window.ethereum) {
        window.showSnackbar(
          "You are disconnected. Please connect to metamask.",
          "info"
        );
      } else {
        window.showSnackbar("Please install metamask.", "info");
      }
    }
    return res;
  }

  static async mintFree(index, address, connection) {
    try {
      const connected = this.checkConnection(connection);
      if (!connected) return false;
      if (window.contract) {
        await window.contract
          .mintNFT(index, address)
          .then((res) => {
            this.onSuccessHandler(res);
          })
          .catch((err) => {
            this.onErrorHandler(err);
          });
      } else {
        window.showSnackbar("Please intall valid provider.", "info");
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  static async isIndexExists(index) {
    try {
      if (this.isEthereumConnected() && window.contract) {
        const isExists = await window.contract.exists(index);
        return isExists;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  static async getERC20ContractAddress() {
    let result = false;
    let blankContract = "0x0000000000000000000000000000000000000000";
    // try {
    //   if (window.contract) {
    //     const erc20Address = await window.contract.sxtToken();
    //     //eslint-disable-next-line
    //     if (erc20Address.toString() != blankContract) {
    //       result = erc20Address;
    //     }
    //   }
    // } catch (err) {
    //   console.log(err.message);
    // }
    return result;
  }

  static async getTransactionReceipt(hash) {
    return window.provider.getTransactionReceipt(hash);
  }

  static async onSuccessHandler(response, setModal) {
    if (response) {
      window.showProgress("Mint processing, Please don't refresh the page.");
      localStorage.setItem("thash", response.hash);
      let progressInterval = setInterval(() => {
        this.getTransactionReceipt(response.hash).then((res) => {
          if (res) {
            localStorage.removeItem("thash");
            window.hideProgress();
            if (res.status === 1) {
              if (typeof setModal === "function") setModal();
              store.dispatch(reloadNFTs());
              window.showSuccessModal();
              clearInterval(progressInterval);
            } else {
              window.showSnackbar("Mint process failed.");
              clearInterval(progressInterval);
            }
          }
        });
      }, 4000);
    }
  }

  static async balanceOf(contract, address, index) {
    return new Promise(async (resolve) => {

      try { 
        let result = false;
        if (contract) {
          const _result = await contract.balanceOf(address, index);
          if (_result) {
            if (this.convertToNumber(_result) > 0) {
              result = true;
            }
          }
        }
        resolve(result);
      } catch (error) {
        console.log('error in balanceOf function', error);
      }
    });
  }

  static async isPreviouslyMinted(contract, address, index) {
    return new Promise(async (resolve) => {
      let result = false;
      if (contract) {
        const _result = await contract.isPreviouslyMintedFrom(index, address);
        if (_result) {
          result = _result;
        }
      }
      resolve(result);
    });
  }

  static async onErrorHandler(err) {
    if (err.error) {
      if (err.error.message.includes("insufficient funds")) {
        window.showSnackbar("Insufficient funds.");
      } else {
        window.showSnackbar(err.error.message);
      }
    } else {
      if (err.message.includes("user rejected")) {
        window.showSnackbar("Transaction rejected by user.");
      } else if (err.message.includes("insufficient funds")) {
        window.showSnackbar("Insufficient funds.");
      } else {
        console.log(err);
        window.showSnackbar(err.message);
      }
    }
  }

  static async freeMint(index, address, setModal) {
    if (window.contract && index > 0) {
      await window.contract
        .mintNFT(index, address)
        .then((res) => {
          this.onSuccessHandler(res, setModal);
        })
        .catch((err) => {
          this.onErrorHandler(err);
        });
    }
  }

  static async mintByEth(index, address, ethPrice, setModal) {
    if (window.contract) {
      console.log(ethPrice);
      await window.contract
        .mintNFTUsingEth(index, address, {
          value: ethPrice.toString(),
        })
        .then((res) => {
          this.onSuccessHandler(res, setModal);
        })
        .catch((err) => {
          this.onErrorHandler(err);
        });
    }
  }

  static async checkAndApproveAllowance(address, erc20Price) {
    return new Promise(async (resolved) => {
      const balance = await window.erc20contract.balanceOf(address);
      if (balance < erc20Price * 1) {
        window.showSnackbar("Insufficient ERC20 token balance.");
        return resolved(0);
      }
      const allowance = await window.erc20contract.allowance(
        address,
        ENV.contractAddress
      );
      if (allowance < erc20Price) {
        //const reqApprovedAmount = erc20Price - allowance;
        //console.log(erc20Price, allowance, reqApprovedAmount);
        window.erc20contract
          .approve(ENV.contractAddress, erc20Price.toString())
          .then((response) => {
            if (response) {
              window.showProgress(
                "Approval processing, Please don't refresh the page."
              );
              let progressInterval = setInterval(() => {
                this.getTransactionReceipt(response.hash).then((res) => {
                  if (res) {
                    window.hideProgress();
                    if (res.status === 1) {
                      clearInterval(progressInterval);
                      return resolved(1);
                    } else {
                      window.showSnackbar("Approval process failed.");
                      clearInterval(progressInterval);
                      return resolved(0);
                    }
                  }
                });
              }, 4000);
            }
          })
          .catch((err) => {
            console.log("checkAndApproveAllowance : ", err);
            sxtService.onErrorHandler(err);
            return resolved(0);
          });
      } else {
        resolved(1);
      }
    });
  }

  static async mintByErc20(index, address, erc20Price, setModal) {
    if (!window.erc20contract)
      return window.showSnackbar("ERC20 contract not found");
    const allowanceApproved = await this.checkAndApproveAllowance(
      address,
      erc20Price
    );
    if (allowanceApproved === 1) {
      await window.contract
        .mintNFTUsingERC20(index, address)
        .then((res) => {
          this.onSuccessHandler(res, setModal);
        })
        .catch((err) => {
          this.onErrorHandler(err);
        });
    }
  }

  static async mintNFT(
    type,
    nftIndex,
    address,
    ethPrice = 0,
    erc20Price = 0,
    setModal = null
  ) {
    return new Promise(async (resolved) => {
      if (typeof setModal !== "function") {
        setModal = () => {};
      }
      let result = false;
      if (type === "free") {
        result = await this.freeMint(nftIndex, address, setModal);
      } else if (type === "eth") {
        result = await this.mintByEth(nftIndex, address, ethPrice, setModal);
      } else if (type === "erc20") {
        result = await this.mintByErc20(
          nftIndex,
          address,
          erc20Price,
          setModal
        );
      }
      return resolved(result);
    });
  }

  static async isEnvironmentReadyToMint() {
    let result = false;
    let haveValidNetwork = null;
    if (window.ethereum) {
      const provider = sxtService._getWeb3Provider();
      haveValidNetwork = await sxtService._checkConnectedNetwork(provider);
    } else {
      window.showSnackbar("Please install metamask.", "info");
      return result;
    }
    if (!haveValidNetwork) return result;
    if (store.getState("connection").connection.disconnected) {
      window.showSnackbar(
        "You are disconnected. Please connect to metamask.",
        "info"
      );
      return result;
    }
    return true;
  }

  static convertToEth(ethPrice) {
    try {
      if (ethPrice) return (ethPrice * 1) / 10 ** 18;
    } catch (err) {
      console.log(err.message);
      return ethPrice;
    }
    return 0;
  }

  static async convertToErc20(erc20Price) {
    try {
      const dec = await this.getERC20Decimal();
      if (erc20Price) return (erc20Price * 1) / 10 ** dec;
      return erc20Price;
    } catch (err) {
      console.log(err.message);
      return erc20Price;
    }
  }
}

export default sxtService;
