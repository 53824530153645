import { BigNumber, ethers } from "ethers";
import React, { useEffect } from "react";
import * as contract_abi from "../assets/json/newContractABI.json";
import { useSelector, useDispatch } from "react-redux";
import { mapContract } from "../redux/states/contractReducer";
import sxtService from "./service";
import ENV from "../environment";

export default function Contract() {
  const connection = useSelector((state) => state.connection);
  const dispatch = useDispatch();
  const contractAddress = ENV.contractAddress;

  useEffect(() => {
    initContract();
    //eslint-disable-next-line
  }, [connection.disconnected]);

  async function initContract() {
    const contract = await sxtService.connectContract(
      contractAddress,
      contract_abi.default.abi,
      connection.disconnected
    );
    if (contract) {
      readContract().then(
        (contract) => contract && dispatch(mapContract(contract))
      );
    }
  }

  window.initContract = () => {
    initContract();
  };

  async function readContract() {
    if (window.contract) {
      try {
        const name = await window.contract.name();
        let currentIndex = await window.contract.currentTokenIndex();
        currentIndex = BigNumber.from(currentIndex).toNumber();
        return {
          name: name,
          contractAddress: contractAddress,
          currentIndex: currentIndex,
        };
      } catch (error) {
        console.error("Error fetching contract name:", error);
        return null;
      }
    } else {
      return null;
    }
  }

  return <></>;
}
