import spaceandtimeLogo from "../../../assets/images/logo_registered_color.svg";
import "./header.scss";
import React from "react";
import {
  Navbar,
  NavbarBrand,
} from "reactstrap";

export default class Example extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <header>
        <div className="container">
          <Navbar color="dark" dark expand="md">
            <NavbarBrand target="_blank" href="https://www.spaceandtime.io/">
              <img src={spaceandtimeLogo} alt="" />
            </NavbarBrand>
          </Navbar>
        </div>
      </header>
    );
  }
}
