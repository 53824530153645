import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "reactstrap";
import SoldNFTSlider from "../../Common/SoldNFTSlider";
import NFTModal from "../../Common/Modal/NFTModal";
import CardSliderNew from "../../Common/AvailableNFTSlider";

export default function NFT() {
  const [modal, setModal] = useState(false);

  return (
    <Container>
      <Row className="d-flex align-items-center main-NFT-style">
        <Col md="6" sm="12" xs="12">
          <div className="dekstop-hide">
            <h2 className="title-lg">
              Community NFTs from Space and Time <br />
              <span> For Free </span>
            </h2>
            <p>
              Explore the exclusive SXT Community NFT collection. Each version
              contains 420 ultra rare collectibles on Ethereum with unique art
              and properties. These NFTs are provided by Space and Time Labs at
              no cost, distributed to our community members as a token of
              appreciation for engagement with our product and community. To get
              involved with the SXT community, join our <a className="discordLink" target="_blank" rel="noopener noreferrer" href="https://discord.gg/spaceandtimedb">Discord</a>.
            </p>
          </div>
          <div>
            <CardSliderNew />
          </div>
        </Col>
        <Col md="6" sm="12" xs="12">
          <div className="mobile-hide">
            <h2 className="title-lg">
              Community NFTs from Space and Time <br />
              <span> For Free </span>
            </h2>
            <p>
              Explore the exclusive SXT Community NFT collection. Each version
              contains 420 ultra rare collectibles on Ethereum with unique art
              and properties. These NFTs are provided by Space and Time Labs at
              no cost, distributed to our community members as a token of
              appreciation for engagement with our product and community. To get
              involved with the SXT community, join our <a className="discordLink" target="_blank" rel="noopener noreferrer" href="https://discord.gg/spaceandtimedb">Discord</a>.
            </p>
          </div>
        </Col>
      </Row>

      {/* SOLD NFTS */}
      <SoldNFTSlider />
      {/* SOLD NFTS CLOSED */}

      <NFTModal show={modal} callback={setModal} />
    </Container>
  );
}
